import React from 'react'


export default function Support() {
  return (
    <div className="privacy">
      <h1>Support</h1>
      <div className='support'>
        <p>For any support write to us at - <a href="mailto:support@onwardworkspaces.com" style={{ textDecoration: 'none', color: "black" }}><b>support@onwardworkspaces.com</b></a></p>
      </div>
    </div >
  )
}

