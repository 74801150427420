import React from 'react'


export default function PrivacyPage() {
    return (
        <div className="privacy">

            <h1>
                Privacy Policy
            </h1>

            <p>At Onward Coworkx Pvt. Ltd. (“Onward”, "we", "us", or "our"), we respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website [website URL] or use our services, in accordance with applicable privacy laws in India, including the Personal Data Protection Bill, 2019.</p>


            <p>This Policy applies solely to the Personal Information (as defined below) collected by us when you use the Website, interact via email, or communicate electronically through the Website. It does not cover information you provide to or that is collected by third parties, such as social media platforms, property consultants, job portals, and others. We encourage you to review the privacy practices of these third parties directly. By accessing or using our website and services, you agree to the terms outlined in this Privacy Policy</p>


            <h5>Information We Collect</h5>

            <p>We collect various types of personal information to provide, maintain, and improve our services:</p>


            <h5> Personal Information</h5>

            <p>Personal Information refers to data that relates to you and can identify you as an individual, either on its own or in combination with other information. It does not include data that has been aggregated or anonymized in a way that prevents you from being identified through means reasonably available to us. We collect Personal Information about you in order to understand your requirement for the Services, address your enquiries regarding the Services, customer support, recruitment purposes. This includes details that you provide when you register, inquire about, or use our services, such as:</p>

            <ul>
                <li>  Name</li>
                <li> Email Addres</li>
                <li> Phone Number</li>
                <li>Company/Organization details</li>
                <li>Billing information</li>
                <li>Payment details when you make a purchase</li>
                <li>KYC details</li>
            </ul>

            <h5>How we collect Information:</h5>

            <p>The Personal Information that we collect, and our use of that information depends on your relationship with us and the requirements of applicable law as described below. We collect information: </p>

            <p> that you provide to us directly.</p>

            <p>that your company, employer, partner, authorised representative provides to us;</p>

            <p>from third parties such as property brokers;</p>

            <p>We may collect data about your interactions with our website, including:</p>

            <ul>

                <li>IP address</li>
                <li> Browser type and version</li>
                <li> Device information</li>
                <li> Pages visited on our site</li>
                <li> Time and date of visit</li>
                <li> Referring website</li>
            </ul>


            <h5>Cookies and Tracking Technologies</h5>


            <p>
                Onward automatically collects information using cookies and other digital markers. These are small files stored on your computer's hard drive or embedded in our website pages, which allow us to identify you and track your activity on the site. You can control the use of cookies through your browser settings.
            </p>


            <h5>How We Use Your Information</h5>

            <p>We use the information we collect for the following purposes:</p>

            <ul>

                <li>To provide and manage our coworking space services</li>
                <li> To respond to inquiries, requests, job applications and customer support</li>
                <li> To process payments and manage billing</li>
                <li> To improve our website and services</li>
                <li> To send you updates, newsletters, and promotional materials (if you opt-in)</li>
                <li> To comply with legal obligations and resolve disputes</li>
            </ul>


            <h5>	 Sharing Your Information</h5>

            <p>We do not share your personal information with third parties except in the following cases:</p>

            <ul>
                <li className='mb-2'><strong>Service Providers: </strong>We may share your information with trusted third-party service providers (e.g., payment processors, IT support) to facilitate our services.</li>
                <li className='mb-2'> <strong>Landlords and Building Management: </strong>In some cases we may share your Personal Information with our landlords and/or building management of our properties so that they can effectively manage and operate the building. </li>
                <li className='mb-2'> <strong>Legal Compliance:</strong> If required by law, we may disclose your information to comply with a legal obligation or governmental request, or to protect our rights and property.</li>
                <li className='mb-2'> <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your personal data may be transferred to the new owner.</li>
            </ul>

            <p>
                We do not sell or rent your personal information to third parties for their marketing purposes.
            </p>

            <h5>Data Security</h5>

            <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or electronic storage method is 100% secure, and we cannot guarantee absolute security.</p>



             <h5>Your Rights</h5>

             <p>You have the following rights concerning your personal information:</p>

               <ul>
                <li><strong>Access:</strong> You may request access to the personal data we hold about you.</li>

                <li> <strong>Correction:</strong> You may request correction or updating of inaccurate or incomplete data.</li>

                <li><strong>Deletion:</strong> You may request the deletion of your personal data, subject to applicable laws and contractual obligations.</li>

                 <li><strong>Opt-out of Communications:</strong> You can opt out of receiving promotional emails at any time by clicking the unsubscribe link in the email or contacting us directly.</li>

                 <li><strong>Data Portability:</strong> You have the right to receive your personal data in a structured, commonly used, and machine-readable format.</li>

               </ul>
            
                <p>To exercise these rights, please contact us at [contact email].</p>


                <h5>Third-Party Links</h5>

                <p>Our website may contain links to third-party websites that are not operated by us. This Privacy Policy applies only to our website, and we are not responsible for the privacy practices of external sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>


                   <h5>International Data Transfers</h5>

                   <p>If you are located outside of India, please note that your personal data may be transferred to, stored, and processed in India, where our servers are located. By using our services, you consent to such international transfers of your data.</p>


                    <h5>Changes to This Privacy Policy</h5>

                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or other factors. We will notify you of any significant changes by posting the updated Privacy Policy on our website, and the "Last Updated" date will be revised accordingly.</p>



                     <h5>	Contact Us</h5>

                     <p>If you have any questions, concerns or complaints about this Privacy Policy or our data practices, please contact us at:</p>



<p> <strong>Onward Coworkx Pvt. Ltd.</strong></p>


<address>
[Company Address]
  <br/>

  coworking space in Okhla Phase 3 Delhi  <br/>

  Email:  performance@onwardworkspaces.com 
</address>

        </div>
    )
}

