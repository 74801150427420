import React from 'react'


export default function TermsOf() {
    return (
        <div className="privacy">

            <h1>
            Terms Of Use
            </h1>


 <h5>Effective Date: [28-11-2024]</h5>

 <p>Welcome to Onward Coworkx Pvt. Ltd. ("we", "our", "us"). By accessing or using our website (the "Website") and mobile application, including browsing, making inquiries, or subscribing to our services, you agree to comply with and be bound by the following Terms of Use ("Terms"). Please read them carefully before using the same. If you do not agree to these Terms, you should refrain from using the Website or mobile application.</p>
            
<h5> Acceptance of Terms</h5>


<p>By using this Website, you agree to comply with these Terms, as well as any additional terms, guidelines, or rules that may apply to specific sections of the Website. These Terms form a legal agreement between you and Onward Coworkx Pvt. Ltd. ("the Company").</p>


<h5>Use of the Website</h5>


<p>You agree to use the Website for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the Website by any third party. Prohibited behavior includes, but is not limited to:</p>


<ul>

    <li>Engaging in any activity that could harm or disrupt the Website or the services offered.</li>

    <li>Attempting to gain unauthorized access to the Website or related systems or networks.</li>

    <li>Using any automated means, such as bots or crawlers, to access the Website without prior consent.</li>
</ul>

<h5>Account Registration</h5>

<p>To access certain services or features on the Website (such as booking a coworking space, signing up for memberships, or receiving newsletters), you may be required to create an account with us. By registering, you agree to provide accurate, complete, and up-to-date information.
You are responsible for maintaining the confidentiality of your account and password, as well as for all activities that occur under your account. If you suspect any unauthorized use of your account, you must immediately notify us.
</p>


<h5>Services and Membership</h5>


<p>The Company provides coworking spaces and related services as outlined on the Website. Memberships, pricing, and services may vary by location and are subject to availability. By registering for services, you agree to the terms outlined in our <strong>Membership Agreement</strong> (which may be provided separately or accessible via the Website) in addition to these Terms.</p>

<h5> Payment Terms</h5>


<p>For any services or products purchased via the Website, you agree to pay all applicable fees as set forth at the time of purchase. Payments may be processed via third-party payment providers, and you agree to comply with their terms and conditions.
Onward Coworkx Pvt. Ltd. reserves the right to modify pricing, cancel services, or change payment terms at its discretion. Refunds, cancellations, or changes to your membership or services will be handled according to the <strong>Refund and Cancellation Policy</strong> available on the Website.
</p>

<h5>User Content</h5>

<p>You may have the ability to submit content (such as reviews, feedback, or other user-generated material) to the Website. By submitting content, you grant us a worldwide, royalty-free, and perpetual license to use, modify, distribute, and display such content in connection with the operation of the Website.</p>

<p>You agree that your content:</p>


<ul>

    <li>	Does not infringe any intellectual property rights or violate any laws.</li>
    <li>	Is accurate and does not contain offensive, harmful, or defamatory material.</li>
</ul>



<h5> Privacy Policy</h5>

<p>Your use of the Website is governed by our <strong>Privacy Policy,</strong> which outlines how we collect, use, and protect your personal information. By using the Website, you consent to the practices described in the Privacy Policy.</p>


<h5>Intellectual Property</h5>

<p>The content, design, and layout of the Website, including text, graphics, logos, images, and software, are protected by copyright and trademark laws. You agree not to reproduce, distribute, or otherwise use any content from the Website without prior written permission from us.</p>


<h5>Limitation of Liability</h5>

<p>To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages, or any loss of profits, revenue, data, or business opportunity arising out of or in connection with your use of the Website or services, even if we have been advised of the possibility of such damages.</p>

<h5> Indemnification</h5>

<p>You agree to indemnify, defend, and hold harmless [Company Name], its affiliates, officers, employees, agents, and licensors from and against any claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of your violation of these Terms or your use of the Website or services.</p>



<h5>Termination</h5>

<p>The Company reserves the right to suspend or terminate your access to the Website or services, without notice, for any reason, including if we believe you have violated these Terms. Upon termination, your right to use the Website and services will immediately cease.</p>



<h5>Governing Law</h5>

<p>These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in New Delhi, India.</p>



<h5> Changes to the Terms</h5>

<p>The Company reserves the right to update or revise these Terms at any time, and such changes will be effective immediately upon posting to the Website. It is your responsibility to review these Terms periodically for updates.</p>


<h5> Contact Us</h5>

<p>If you have any questions about these Terms or our services, please contact us at:</p>


<address>
[Company Address]
  <br/>

  coworking space in Okhla Phase 3 Delhi  <br/>

  Email: performance@onwardworkspaces.com
</address>

        </div>
    )
}

